import {
  requestFileUploadUrl,
  requestFileDownloadUrl,
} from '@wix/communities-blog-client-common/dist/src/services/wix-media/request-url';

import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const requestFileUploadUrlPromisified = createPromisifiedAction(
  (fileName, mimeType) => (dispatch, getState, { request }) => requestFileUploadUrl(request, fileName, mimeType),
  c => c,
  resp => resp.status,
);

export const requestFileDownloadUrlPromisified = createPromisifiedAction(
  (postId, filePath) => (dispatch, getState, { request }) => requestFileDownloadUrl(request, postId, filePath),
  c => c,
  resp => resp.status,
);
