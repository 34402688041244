import { get, compact } from 'lodash';
import { Router } from '../../common/router';
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_CATEGORY,
  ROUTE_CATEGORIES,
  ROUTE_SEARCH,
  ROUTE_LOGIN,
  ROUTE_ARCHIVE,
} from '../constants/routes';
import {
  SECTION_HOMEPAGE,
  IS_BANNER_ENABLED_PATH,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import fetchFeedPosts from '../../common/actions/fetch-feed-posts';
import fetchArchive from '../../common/actions/fetch-archive';
import { getPaginationSettings } from '../hoc/with-pagination-settings';
import { isExcludePostContentSupported } from '../../common/selectors/layout-selectors';
import { getAppSettingsValue } from '../../common/selectors/app-settings-selectors';
import { fetchLastFeaturedPost } from '../../common/actions/fetch-last-featured-post';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import search, { clearSearchResults, MIN_QUERY_LENGTH } from '../../search/actions/search';
import { decodeQuery } from '../../search/services/query-encoding';
import { fetchCategory } from '../actions/fetch-category';
import { resolveCategorySlug } from '../../common/services/slug';
import { fetchCategoryPosts } from '../../common/actions/fetch-category-posts';
import {
  isSSR,
  getIsMobile,
  isEditor,
  getLanguage,
  isSite,
} from '../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../common/actions/page-opened';
import {
  FEED_PAGE,
  SEARCH_RESULTS,
  CATEGORY_PAGE,
  ARCHIVE_PAGE,
  FEED_PAGE_NOT_FOUND,
} from '../../common/services/detect-route';
import { generateFeedSEOTags, generateCategorySEOTags } from '../../common/services/generate-seo-tags';
import { getSectionUrl } from '../../common/store/topology/topology-selectors';
import { getFeedPostsByPage, getPostsByCategoryIdAndPage } from '../../common/selectors/post-selectors';
import { getCategoriesMap } from '../../common/selectors/categories-selectors';
import { getCategorySchema } from '../../common/services/json-schema';
import { initI18N, translate } from '../../common/services/controller-translate';
import controllerTranslations from '../../common/controller-translations.json';
import { getSchemaEnabled } from '../../common/selectors/app-data-selectors';
import { setMetaTagRobotsNoIndex } from '../../common/services/set-metatag-robots-noindex';
import { getAppConfig } from '../../common/store/app-config/app-config-selectors';
import { setFeedSEOTags } from '../actions/set-feed-seo-tags';
import { getFeedSEOTags } from '../selectors/feed-seo-tags-selectors';
import { createNotFoundPageRouter } from '../../common/controller/create-router';

const createFeedPageRouter = (store, wixCodeApi, appConfig) => ({ params, route }, redirect) => {
  const isBannerEnabled = getAppSettingsValue({ state: store.getState(), key: IS_BANNER_ENABLED_PATH });
  const page = parseInt(get(params, 'page', '1'), 10);
  const actions = compact([
    store.dispatch(
      fetchFeedPosts({
        page,
        excludeContent: isExcludePostContentSupported(store.getState(), SECTION_HOMEPAGE),
        section: SECTION_HOMEPAGE,
      }),
    ),
    isBannerEnabled && store.dispatch(fetchLastFeaturedPost()),
  ]);

  return Promise.all(actions).then(() => {
    const { showPagination, isValidPage } = getPaginationSettings(SECTION_HOMEPAGE, store.getState(), { params });
    if (showPagination && !isValidPage) {
      redirect(ROUTE_404);
    }

    const state = store.getState();

    !isSSR(state) &&
      store.dispatch(pageOpened({ page: FEED_PAGE, isMobile: getIsMobile(state), isEditor: isEditor(state) }));

    if (isSite(state)) {
      !getFeedSEOTags(state) &&
        store.dispatch(
          setFeedSEOTags({
            title: wixCodeApi.seo.title,
            links: wixCodeApi.seo.links,
            metaTags: wixCodeApi.seo.metaTags,
          }),
        );

      const { title, links, metaTags } = generateFeedSEOTags({
        appConfig,
        showPagination,
        state: store.getState(),
        page,
        t: translate,
      });

      wixCodeApi.seo.setLinks(links);
      wixCodeApi.seo.setMetaTags(metaTags);

      if (showPagination) {
        wixCodeApi.seo.setTitle(title);
      }
    }
  });
};

const createSearchPageRouter = (store, wixCodeApi) => ({ params: { query } }) => {
  setMetaTagRobotsNoIndex(wixCodeApi);
  store.dispatch(clearSearchResults());
  if (query && query.length >= MIN_QUERY_LENGTH) {
    store.dispatch(setIsLoading('search', '', true));
    return store.dispatch(search({ query: decodeQuery(query) }));
  }
  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: SEARCH_RESULTS }));
};

const createArchivePageRouter = (store, wixCodeApi) => ({ params }) => {
  setMetaTagRobotsNoIndex(wixCodeApi);

  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: ARCHIVE_PAGE }));

  const { year, month, pageIndex } = params;
  if (year && month) {
    return store.dispatch(fetchArchive(year, month, pageIndex));
  }
};

const createCategoryPageRouter = (store, wixCodeApi, appConfig) => ({ params }, redirect) => {
  let category;
  const page = parseInt(get(params, 'page', '1'), 10);
  return store
    .dispatch(fetchCategory(resolveCategorySlug(params)))
    .then(_category => {
      category = _category;
      return store.dispatch(
        fetchCategoryPosts({
          categoryId: _category._id,
          page,
          excludeContent: isExcludePostContentSupported(store.getState(), SECTION_CATEGORY),
        }),
      );
    })
    .then(() => {
      const { showPagination, isValidPage } = getPaginationSettings(SECTION_CATEGORY, store.getState(), { params });
      if (showPagination && !isValidPage) {
        redirect(ROUTE_404);
      }

      const state = store.getState();
      if (isSite(state)) {
        const categorySEOTags = generateCategorySEOTags({
          appConfig,
          category,
          showPagination,
          state,
          page,
          t: translate,
        });
        wixCodeApi.seo.renderSEOTags(categorySEOTags);

        if (appConfig.isWP || getSchemaEnabled(state)) {
          const currentPagePosts = getPostsByCategoryIdAndPage(state, category._id, page);
          wixCodeApi.seo.setStructuredData([
            getCategorySchema(appConfig, category, currentPagePosts, getCategoriesMap(state)),
          ]);
        }
      }

      !isSSR(store.getState()) && store.dispatch(pageOpened({ page: CATEGORY_PAGE, category }));
    })
    .catch(error => {
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    });
};

export const createRouter = (store, config, wixCodeApi, compId) => {
  initI18N(controllerTranslations, getLanguage(store.getState()));
  const appConfig = getAppConfig(store.getState());
  const router = new Router();
  router.add(ROUTE_CATEGORIES, createCategoryPageRouter(store, wixCodeApi, appConfig));
  router.add(ROUTE_CATEGORY, createCategoryPageRouter(store, wixCodeApi, appConfig));
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(ROUTE_ARCHIVE, createArchivePageRouter(store, wixCodeApi));
  router.add(ROUTE_HOME, createFeedPageRouter(store, wixCodeApi, appConfig));
  router.add(ROUTE_404, createNotFoundPageRouter(store, wixCodeApi, FEED_PAGE_NOT_FOUND, ROUTE_404));
  router.add(ROUTE_LOGIN);
  router.fallback(ROUTE_404);
  return router;
};
